import React, { useState, useEffect, useRef } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';

import {
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  DropdownButton,
  Pagination,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import firebase from 'firebase';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { useToast } from '../../../hooks/Toast';
import Button from '../../../components/Button';
import CustomPagination from '../../../components/CustomPagination';
import api from '../../../services/api';
import Loader from '../../../components/Loader';
import { useAuth } from '../../../hooks/Auth';

type FieldsItemType = {
  name: string;
};

interface Speak {
  id: string;
  name: string;
  picture: string;
  description: string;
}

interface Speakers {
  id: string;
  createdAt: number;
  updatedAt: number;
  name?: string;
  picture?: string;
  order?: string;
  description: {
    pt: string;
    en: string;
    es: string;
  };
  eventId: string;
}

interface Agenda {
  id: string;

  day: string;

  date: string;

  hour: string;

  profission: string;

  name: string;

  descriptionH3: string;

  descriptionP: string;

  orderNumber: string;

  eventKey: string;

  eventId: string;

  speaker?: Partial<Speak>;
}

const Agenda: React.FC<{ event: any }> = (props: any) => {
  const [registerField, setRegisterField] = useState<any>({});
  const { register, setValue, handleSubmit, errors, reset } = useForm<Agenda>({
    defaultValues: {
      id: '',
      day: '',
      date: '',
      hour: '',
      profission: '',
      name: '',
      descriptionH3: '',
      descriptionP: '',
      orderNumber: '',
      eventKey: '',
      eventId: '',
      speaker: undefined,
    },
  });
  const [isBusy, setIsBusy] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [agenda, setAgenda] = useState<Agenda[]>([]);
  const [speakers, setSpeakers] = useState<Speakers[]>([]);
  const [speakerIdSelect, setSpeakerIdSelect] = useState<string>('');
  const [orderNumber, setOrderNumber] = useState<number>(1);
  const { t } = useTranslation();
  const { addToast } = useToast();

  const loadSpeakers = () => {
    const unsubscribe = firebase
      .firestore()
      .collection('speaker')
      .where('eventId', '==', props.event.id)
      .onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((m) => ({
          ...m.data(),
          id: m.id,
        }));
        const spe = docs.sort(function (a: any, b: any) {
          return a.order - b.order;
        });
        setSpeakers(spe as Array<Speakers>);
      });

    return () => {
      unsubscribe();
    };
  };

  const loadAgenda = async () => {
    let unsubscribe: any = null;
    try {
      if (!props.event.id) return;

      const data = await api
        .get(`/agenda/${props.event.id}`)
        .then((r) => {
          const sortedDataByOrderNumber = r.data.sort(
            (a: Agenda, b: Agenda) =>
              Number(a.orderNumber) - Number(b.orderNumber),
          );
          setAgenda(sortedDataByOrderNumber);

          return sortedDataByOrderNumber;
        })
        .finally(() => setIsBusy(false));

      unsubscribe = firebase
        .firestore()
        .collection('agenda')
        .where('eventId', '==', props.event.id)
        .orderBy('day', 'asc')
        .orderBy('orderNumber', 'asc')
        .onSnapshot(
          (snapshot) => {
            const docs: Agenda[] = snapshot.docs.map((m) => ({
              ...(m.data() as Agenda),
              id: m.id,
            }));

            if (docs.length >= data.length) setAgenda(docs);
          },
          (error) => {
            console.log('error agenda snapshot', error);
          },
        );
    } catch (error) {
    } finally {
      setIsBusy(false);
    }

    return () => {
      unsubscribe?.();
    };
  };

  useEffect(() => {
    if (!props?.event?.id) return;

    let unsubscribe: any = null;

    if (props?.event?.id) {
      unsubscribe = loadSpeakers();
      loadAgenda();
    }

    return () => {
      unsubscribe?.();
    };
  }, [props.event.id]);

  useEffect(() => {
    if (registerField.id && speakers.length > 0) {
      Object.keys(registerField).forEach((key) => {
        // if (key === 'speaker') {
        //   setValue(registerField[key].id);
        // } else
        setValue(key, registerField[key]);
      });
    }
  }, [registerField, speakers]);

  function onError(error: any) {
    console.log('onSubmitError', error);
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', { data, loading });

    if (loading) return;
    setLoading(true);
    try {
      // const value = {
      //   ...registerField,
      //   ...data,
      //   speaker:
      //     speakers[
      //       speakerIdSelect
      //         ? speakers.findIndex((e) => e.id === speakerIdSelect)
      //         : data.speaker
      //     ],
      //   descriptionH3: data.descriptionH3,
      //   descriptionP: data.descriptionP,
      //   hour: data.hour,
      //   orderNumber: orderNumber,
      //   eventId: props.event.id,
      // };
      // console.log(value);

      const payload = { ...data };
      if (!data.id) delete data.id;
      if (data.speaker.id) {
        data.speaker = speakers.find((s) => s.id === data.speaker.id);
      }

      await api.post('/agenda', { ...data, eventId: props.event.id });

      // loadAgenda();
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro',
        description:
          error?.response?.data?.message ||
          error.message ||
          t('an error has ocurred on register, try again.'),
      });
    } finally {
      setLoading(false);
      reset();
      setRegisterField({});
    }
  };

  const deleteItem = (id: string) => {
    api.delete(`/agenda/${id}`).catch((error) => {
      addToast({
        type: 'error',
        title: 'Erro',
        description:
          error?.response?.data?.message ||
          error.message ||
          t('an error has ocurred on register, try again.'),
      });
    });
    // .finally(() => loadAgenda());
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="name">
              {t("name of the day's talk")}
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <Form.Control
              type="hidden"
              name="id"
              autoComplete="off"
              ref={register({})}
            />
            <>
              {errors[`name`] && (
                <span className="error">{t('name is obligatory')}</span>
              )}
            </>
          </Form.Group>

          {/* <Form.Group as={Col} md="6">
                        <Form.Label htmlFor="profission">Descrição do palestrante (Profission)</Form.Label>
                        <Form.Control
                            type="text"
                            name="profission"
                            autoComplete="off"
                            ref={register({ required: true })}
                        />
                    </Form.Group>

                    <>
                        {errors[`profission`] && (
                            <span className="error">Descrição do palestrante</span>
                        )}
                    </> */}

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="day">{t('day')}</Form.Label>
            <Form.Control
              type="string"
              name="day"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`day`] && (
                <span className="error">{t('day is obligatory')}</span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="date">{t('date')}</Form.Label>
            <Form.Control
              type="text"
              name="date"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`date`] && (
                <span className="error">{t('date is obligatory')}</span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="hour">{t('hour')}</Form.Label>
            <Form.Control
              type="text"
              name="hour"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`hour`] && (
                <span className="error">{t('hour is obligatory')}</span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="descriptionH3">
              {t('description')}Descrição H3
            </Form.Label>
            <Form.Control
              type="text"
              name="descriptionH3"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`descriptionH3`] && (
                <span className="error">
                  {t('description H3 is obligatory')}
                </span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="descriptionP">{t('description')} P</Form.Label>
            <Form.Control
              type="text"
              name="descriptionP"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`descriptionP`] && (
                <span className="error">
                  {t('description P is obligatory')}
                </span>
              )}
            </>
          </Form.Group>

          {/* {fields.map((field) => field.name !== 'avatar' && (
                        <Form.Group as={Col} md="4" key={`field.${field.name}`}>
                            <Form.Label htmlFor={`fields[${field}]`}>{field.name}</Form.Label>
                            <Form.Control
                                type="text"
                                name={`fields[${field.name}]`}
                                autoComplete="off"
                                ref={register({ required: false })}
                            />
                        </Form.Group>
                    ))} */}

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="speaker">{t('speaker')}</Form.Label>
            <Form.Control name="speaker.id" as="select" ref={register({})}>
              <option value="">{t('select')}</option>
              {speakers.map((res) => {
                // if (res.id !== speakerIdSelect) {
                return (
                  <option key={`schedule_speaker_${res.id}`} value={res.id}>
                    {res.name}
                  </option>
                );
                // }
              })}
            </Form.Control>
            <>
              {errors[`speaker`] && (
                <span className="error">{t('speaker is obligatory')}</span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="orderNumber">{t('order')}</Form.Label>
            <Form.Control
              name="orderNumber"
              as="select"
              ref={register({ required: true })}
            >
              <option value="">{t('select')}</option>
              <option value={'1'}>{'1'}</option>
              <option value={'2'}>{'2'}</option>
              <option value={'3'}>{'3'}</option>
              <option value={'4'}>{'4'}</option>
              <option value={'5'}>{'5'}</option>
              <option value={'6'}>{'6'}</option>
              <option value={'7'}>{'7'}</option>
            </Form.Control>
            <>
              {errors[`orderNumber`] && (
                <span className="error">{t('order is obligatory')}</span>
              )}
            </>
          </Form.Group>
        </Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          className="mt-3"
          style={{ textAlign: 'right' }}
        >
          <Button type="button" onClick={() => reset()}>
            {t('cancel')}
          </Button>
          <Button loading={loading} color="primary" type="submit">
            {t('save')}
          </Button>
        </Form.Group>
      </Form>

      <Table size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{t('name')}</th>
            <th>{t('date')}</th>
            <th>{t('day')}</th>
            <th>{t('hour')}</th>
            <th>{t('speaker')}</th>
            <th>{t('description')}</th>
            <th>{t('order')}</th>
            <th>{t('update')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isBusy && (
            <tr>
              <td colSpan={12} className="text-center">
                <Loader />
              </td>
            </tr>
          )}
          {!isBusy &&
            agenda.map((item: Agenda, i: number) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{item?.name}</td>
                <td>{item?.date}</td>
                <td>{item?.day}</td>
                <td>{item?.hour}</td>
                <td>{item?.speaker?.name}</td>
                <td>{item?.profission}</td>
                <td>{item?.orderNumber}</td>

                <td>
                  <Row>
                    <Col xl={3} md={6}>
                      <DropdownButton title={t('update')}>
                        <Dropdown.Item onClick={() => setRegisterField(item)}>
                          {t('edit')}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => deleteItem(item.id)}>
                          {t('delete')}
                        </Dropdown.Item>
                      </DropdownButton>
                    </Col>
                  </Row>
                </td>
                <td></td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default Agenda;
